import React, { useState, useEffect } from "react";
import { TallyImage } from "../types";

interface Props {
  images: TallyImage[] | string[];
}

const ImageCarousel: React.FC<Props> = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(false);

  useEffect(() => {
    let interval: string | number | NodeJS.Timer | undefined;
    if (isAutoPlaying) {
      interval = setInterval(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1,
        );
      }, 3000);
    }
    return () => clearInterval(interval);
  }, [isAutoPlaying, images.length]);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1,
    );
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1,
    );
  };

  const goToSlide = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <div className="relative w-full max-w-4xl mx-auto">
      <div className="relative h-96 overflow-hidden rounded-lg">
        {/* Images */}
        {images.map((image, index) => (
          <div
            key={index}
            className={`absolute w-full h-full transition-transform duration-500 ease-in-out ${
              index === currentIndex ? "translate-x-0" : "translate-x-full"
            }`}
            style={{
              transform: `translateX(${100 * (index - currentIndex)}%)`,
            }}
          >
            {typeof image === "string" ? (
              <img
                src={`data:image/png;base64,${image}`}
                alt="User"
                className="w-full h-full object-cover"
              />
            ) : (
              <img
                src={image.url}
                alt={image.name}
                className="w-full h-full object-cover"
              />
            )}
          </div>
        ))}
        {images.length > 1 && (
          <>
            {/* Navigation Buttons */}
            <button
              onClick={prevSlide}
              className="absolute left-4 top-1/2 -translate-y-1/2 bg-black/30 hover:bg-black/50 text-white w-8 h-8 rounded-full transition-colors grid place-items-center"
            >
              <div className="rotate-45 w-2.5 h-2.5 border-b-2 border-l-2 border-white" />
            </button>
            <button
              onClick={nextSlide}
              className="absolute right-4 top-1/2 -translate-y-1/2 bg-black/30 hover:bg-black/50 text-white w-8 h-8 rounded-full transition-colors grid place-items-center"
            >
              <div className="rotate-45 w-2.5 h-2.5 border-t-2 border-r-2 border-white" />
            </button>

            {/* Dots Navigation */}
            <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex space-x-2">
              {images.map((_, index) => (
                <button
                  key={index}
                  onClick={() => goToSlide(index)}
                  className={`w-3 h-3 rounded-full transition-colors ${
                    index === currentIndex ? "bg-white" : "bg-white/50"
                  }`}
                />
              ))}
            </div>

            {/* Auto-play Toggle */}
            <button
              onClick={() => setIsAutoPlaying(!isAutoPlaying)}
              className="absolute top-4 right-4 bg-black/30 hover:bg-black/50 text-white px-3 py-1 rounded-full text-sm transition-colors"
            >
              {isAutoPlaying ? "Pause" : "Play"}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default ImageCarousel;
