import { useState, useCallback } from "react";

import { Match, User, UserSummary } from "../types";
import {
  getUser,
  getUsers,
  modifyMatchNoteOrBioService,
  requestAnotherMatchService,
  toggleAutomatedResponseService,
  archiveMatchService,
  sendMatchService,
  archiveUserService,
  setHideNeedsMatchAlertsService,
  createIntroductionService,
  generateMatchService,
  unsubscribeUserService,
} from "../services/crmService";
import { useAuth0 } from "@auth0/auth0-react";

const useCRM = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [users, setUsers] = useState<UserSummary[]>([]);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [note, setNote] = useState("");
  const [bio, setBio] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedMatch, setSelectedMatch] = useState<Match | null>(null);

  const setHideNeedsMatchAlerts = useCallback(
    async (phoneNumber: string, hideNeedsMatchAlerts: boolean) => {
      setLoading(true);
      setError(null);
      try {
        const accessToken = await getAccessTokenSilently();
        await setHideNeedsMatchAlertsService(
          phoneNumber,
          hideNeedsMatchAlerts,
          accessToken
        );
      } catch (err) {
        setError("Failed to toggle needs match");
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const archiveUser = useCallback(async (userId: string) => {
    setLoading(true);
    setError(null);
    try {
      const accessToken = await getAccessTokenSilently();
      await archiveUserService(userId, accessToken);
    } catch (err) {
      setError("Failed to archive user");
    } finally {
      setLoading(false);
    }
  }, []);

  const modifyMatchNoteOrBio = useCallback(
    async (matchId: string, note: string, bio: string) => {
      setLoading(true);
      setError(null);
      try {
        const accessToken = await getAccessTokenSilently();
        await modifyMatchNoteOrBioService(matchId, note, bio, accessToken);
      } catch (err) {
        setError("Failed to modify match note or bio");
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const handleEditClick = (match: Match) => {
    setSelectedMatch(match);
    setNote(match.personalized_matchmaker_note);
    setBio(match.prospect_bio);
    setIsModalOpen(true);
  };

  const searchUsers = useCallback(
    async (
      query: string,
      sortBy: string,
      filterByMustHave: string[],
      filterByMustNotHave: string[]
    ) => {
      setLoading(true);
      setError(null);
      try {
        const accessToken = await getAccessTokenSilently();
        const fetchedUsers = await getUsers(
          query,
          accessToken,
          sortBy,
          filterByMustHave,
          filterByMustNotHave
        );
        setUsers(fetchedUsers);
      } catch (err) {
        setError("Failed to fetch users");
      } finally {
        setLoading(false);
      }
    },
    []
  );

  const fetchUserDetails = useCallback(async (userId: string) => {
    setLoading(true);
    setError(null);
    try {
      const accessToken = await getAccessTokenSilently();
      const user = await getUser(userId, accessToken);
      setSelectedUser(user);
    } catch (err) {
      setError("Failed to fetch user details");
    } finally {
      setLoading(false);
    }
  }, []);

  const requestAnotherMatch = useCallback(async (phoneNumber: string) => {
    setLoading(true);
    setError(null);
    try {
      const accessToken = await getAccessTokenSilently();
      await requestAnotherMatchService(phoneNumber, accessToken);
      // alert("Successfully requested another match");
      console.log("Successfully requested another match");
    } catch (err) {
      setError("Failed to request another match");
      alert("Failed to request another match");
    } finally {
      setLoading(false);
    }
  }, []);

  const toggleAutomatedResponse = useCallback(async (phoneNumber: string) => {
    setLoading(true);
    setError(null);
    try {
      const accessToken = await getAccessTokenSilently();
      const updatedUser = await toggleAutomatedResponseService(
        phoneNumber,
        accessToken
      );
      setSelectedUser(updatedUser);
    } catch (err) {
      setError("Failed to toggle automated response");
    } finally {
      setLoading(false);
    }
  }, []);

  const archiveMatch = useCallback(async (matchId: string) => {
    setLoading(true);
    setError(null);
    try {
      const accessToken = await getAccessTokenSilently();
      await archiveMatchService(matchId, accessToken);
    } catch (err) {
      setError("Failed to archive match");
    } finally {
      setLoading(false);
    }
  }, []);

  const sendMatch = useCallback(async (matchId: string) => {
    setLoading(true);
    setError(null);
    try {
      const accessToken = await getAccessTokenSilently();
      await sendMatchService(matchId, accessToken);
    } catch (err) {
      setError("Failed to send match");
    } finally {
      setLoading(false);
    }
  }, []);

  const createIntroduction = useCallback(async (matchId: string) => {
    setLoading(true);
    setError(null);
    try {
      const accessToken = await getAccessTokenSilently();
      await createIntroductionService(matchId, accessToken);
    } catch (err) {
      setError("Failed to create introduction");
    } finally {
      setLoading(false);
    }
  }, []);

  const generateMatch = useCallback(async (phoneNumber: string) => {
    setLoading(true);
    setError(null);
    try {
      const accessToken = await getAccessTokenSilently();
      await generateMatchService(phoneNumber, accessToken);
      alert("Successfully generated match");
    } catch (err) {
      setError("Failed to generate match");
    } finally {
      setLoading(false);
    }
  }, []);

  const unsubscribeUser = useCallback(async (phoneNumber: string) => {
    setLoading(true);
    setError(null);
    try {
      const accessToken = await getAccessTokenSilently();
      await unsubscribeUserService(phoneNumber, accessToken);
    } catch (err) {
      setError("Failed to unsubscribe user");
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    users,
    selectedUser,
    loading,
    error,
    archiveUser,
    searchUsers,
    fetchUserDetails,
    requestAnotherMatch,
    generateMatch,
    toggleAutomatedResponse,
    modifyMatchNoteOrBio,
    handleEditClick,
    archiveMatch,
    sendMatch,
    setHideNeedsMatchAlerts,
    createIntroduction,
    unsubscribeUser,
  };
};

export default useCRM;
