import constants from "../constants";

export const getSMSContacts = async (
  accessToken: string,
  query?: string,
  offset: number = 0,
  limit: number = 20,
) => {
  let url = `${constants.BACKEND_API_ENDPOINT}/internal/sms/contacts?offset=${offset}&limit=${limit}`;
  if (query) {
    url += `&query=${query}`;
  }
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (!response.ok) {
    throw new Error("Failed to fetch SMS contacts");
  }
  return response.json();
};

export const getSMSMessages = async (
  contactId: string,
  accessToken: string,
) => {
  const url = `${constants.BACKEND_API_ENDPOINT}/internal/sms/messages?user=${contactId}`;
  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (!response.ok) {
    throw new Error("Failed to fetch SMS messages");
  }
  return response.json();
};

export const sendMessageToContact = async (
  contactId: string,
  message: string,
  accessToken: string,
) => {
  const url = `${constants.BACKEND_API_ENDPOINT}/internal/sms/messages`;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      user_id: contactId,
      message: message,
    }),
  });
  if (!response.ok) {
    throw new Error("Failed to send message");
  }
  return response.json();
};
