import React, { FC, useEffect, useState } from "react";
import { Match } from "../../types";
import useCRM from "../../hooks/useCRM";
import { useParams } from "react-router-dom";
import constants from "../../constants";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import ImageCarousel from "../../components/ImageCarousel";

const UserDetailsPage: FC = () => {
  const { id } = useParams<{ id: string }>();
  const {
    selectedUser,
    loading,
    error,
    fetchUserDetails,
    generateMatch,
    toggleAutomatedResponse,
    modifyMatchNoteOrBio,
    archiveMatch,
    sendMatch,
    archiveUser,
    unsubscribeUser,
    setHideNeedsMatchAlerts,
    createIntroduction,
  } = useCRM();
  const [numMessagesToShow, setNumMessagesToShow] = useState(5);
  const [selectedMatch, setSelectedMatch] = useState<Match | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [note, setNote] = useState("");
  const [bio, setBio] = useState("");

  useEffect(() => {
    if (id) {
      fetchUserDetails(id);
    }
  }, [id, fetchUserDetails]);

  const handleEditClick = (match: Match) => {
    setSelectedMatch(match);
    setNote(match.personalized_matchmaker_note);
    setBio(match.prospect_bio);
    setIsModalOpen(true);
  };

  const handleSaveChanges = async () => {
    if (selectedMatch) {
      await modifyMatchNoteOrBio(selectedMatch._id, note, bio);
    }
    setIsModalOpen(false);
    // Optionally, refresh the match data here
  };

  const handleDeleteClick = async (matchId: string) => {
    await archiveMatch(matchId);
    // Optionally, refresh the match data here
  };

  const handleArchiveUser = async () => {
    await archiveUser(id ?? "");
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!selectedUser) return <div>User not found</div>;

  const events = Object.entries(selectedUser)
    .filter(([key, value]) => key.startsWith("event_") && value)
    .map(([key, value]) => ({
      name: key.replace("event_", "").replace(/_/g, " "),
      date: value,
    }));

  // sort events by date
  events.sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
  );

  return (
    <div className="container mx-auto p-8 max-w-7xl">
      <div className="flex items-center justify-between mb-8">
        <h1 className="text-3xl font-bold">User Profile</h1>
        <a
          href="/crm"
          className="text-blue-700 hover:text-blue-900 font-medium"
        >
          ← Back to CRM
        </a>
      </div>

      {/* Profile Header Section */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
          {/* Photo Section */}
          <div className="col-span-1">
            <ImageCarousel images={selectedUser.photos} />
          </div>

          {/* Basic Info Section */}
          <div className="col-span-2">
            <div className="flex justify-between items-start mb-6">
              <div>
                <h2 className="text-2xl font-semibold">
                  {selectedUser.name} {selectedUser.last_name}
                </h2>
                <div className="flex gap-3 mt-2">
                  <span className="px-3 py-1 rounded-full text-sm bg-gray-100">
                    {selectedUser.computed_age} years
                  </span>
                  <span className="px-3 py-1 rounded-full text-sm bg-gray-100">
                    {selectedUser.gender}
                  </span>
                  <span
                    className={`px-3 py-1 rounded-full text-sm ${
                      selectedUser.is_accepted
                        ? "bg-green-100 text-green-800"
                        : "bg-yellow-100 text-yellow-800"
                    }`}
                  >
                    {selectedUser.is_accepted ? "Accepted" : "Pending"}
                  </span>
                  <span
                    className={`px-3 py-1 rounded-full text-sm ${
                      selectedUser.paying_status === "Paid"
                        ? "bg-emerald-100 text-emerald-800"
                        : selectedUser.paying_status === "Trial"
                        ? "bg-blue-100 text-blue-800"
                        : "bg-red-100 text-red-800"
                    }`}
                  >
                    {selectedUser.paying_status || "Not Paying"}
                  </span>
                </div>
              </div>
              <div className="flex gap-3">
                <button
                  onClick={() => handleArchiveUser()}
                  className="px-4 py-2 text-sm rounded-md border border-red-200 text-red-600 hover:bg-red-50"
                >
                  Archive User
                </button>
                <button
                  onClick={() => unsubscribeUser(selectedUser.phone_number)}
                  className="px-4 py-2 text-sm rounded-md border border-red-200 text-red-600 hover:bg-red-50"
                >
                  Unsubscribe User
                </button>
              </div>
            </div>

            <div className="grid grid-cols-2 gap-x-12 gap-y-4">
              <div className="flex items-center gap-2">
                <span className="text-gray-500">Phone:</span>
                {selectedUser.phone_number}
              </div>
              <div className="flex items-center gap-2">
                <span className="text-gray-500">Email:</span>
                {selectedUser.email}
              </div>
              <div className="flex items-center gap-2">
                <span className="text-gray-500">Target Gender:</span>
                <span className="px-2 py-1 rounded-full text-sm bg-blue-100 text-blue-800">
                  {selectedUser.target_gender}
                </span>
              </div>
              <div className="flex items-center gap-2">
                <span className="text-gray-500">Timezone:</span>
                <span>{selectedUser.timezone}</span>
              </div>
              <div className="flex items-center gap-2">
                <span className="text-gray-500">Signup Location:</span>
                <span>{selectedUser.signup_location}</span>
              </div>
              <div className="flex items-center gap-2">
                <span className="text-gray-500">Matching Location:</span>
                <span>{selectedUser.matching_location}</span>
              </div>
              <div className="flex items-center gap-2 col-span-2">
                <span className="text-gray-500">Intro Tracker:</span>
                <a
                  href={selectedUser.intro_tracker_link}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-600 hover:text-blue-800 truncate max-w-md"
                >
                  {selectedUser.intro_tracker_link?.split("/").pop() ||
                    "View Tracker"}
                </a>
              </div>
              <div className="flex items-center justify-between col-span-1">
                <span className="text-gray-500">Automated Response:</span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={selectedUser.automated_response_enabled}
                    onChange={() =>
                      toggleAutomatedResponse(selectedUser.phone_number)
                    }
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <div className="flex items-center justify-between col-span-1">
                <span className="text-gray-500">Hide Match Alerts:</span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={selectedUser.hide_needs_match_alerts}
                    onChange={() =>
                      setHideNeedsMatchAlerts(
                        selectedUser.phone_number,
                        !selectedUser.hide_needs_match_alerts,
                      )
                    }
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              {selectedUser.needs_match && (
                <div className="col-span-2">
                  <p className="text-red-500 text-sm font-medium">
                    Needs Match
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Events Timeline Section */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h3 className="text-xl font-semibold mb-6">Events Timeline</h3>
        <div className="space-y-4">
          {events.map((event, index) => (
            <div key={index} className="flex items-start">
              <div className="flex-shrink-0 w-4 h-4 mt-1.5 rounded-full bg-blue-500" />
              <div className="ml-4 flex-grow">
                <div className="flex items-center justify-between">
                  <span className="font-medium">{event.name}</span>
                  <span className="text-sm text-gray-500">
                    {new Date(event.date).toLocaleString()}
                  </span>
                </div>
              </div>
            </div>
          ))}
          {events.length === 0 && (
            <p className="text-gray-500 text-center">No events recorded</p>
          )}
        </div>
      </div>

      {/* Messages & Calls Section */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-8">
        <div className="bg-white rounded-lg shadow-md p-6">
          <div className="flex items-center justify-between mb-4">
            <h3 className="text-lg font-semibold">Recent Messages</h3>
            <div className="space-x-2">
              <a
                href="/sms"
                target="_blank"
                rel="noreferrer"
                className="text-blue-700 hover:text-blue-900"
              >
                Open Chat
              </a>
              <button
                onClick={() => setNumMessagesToShow(numMessagesToShow + 5)}
                className="text-blue-700 hover:text-blue-900"
              >
                Show More
              </button>
            </div>
          </div>

          <div className="space-y-4 max-h-96 overflow-y-auto">
            {selectedUser.messages && selectedUser.messages.length > 0 ? (
              selectedUser.messages
                .slice(-numMessagesToShow)
                .map((message, index) => (
                  <div
                    key={index}
                    className={`flex ${
                      message.sender === "assistant"
                        ? "justify-end"
                        : "justify-start"
                    }`}
                  >
                    <div
                      className={`max-w-[80%] p-3 rounded-lg ${
                        message.sender === "assistant"
                          ? "bg-blue-500 text-white"
                          : "bg-gray-100"
                      }`}
                    >
                      <p className="whitespace-pre-wrap text-sm">
                        {message.message}
                      </p>
                      {message.timestamp && (
                        <p className="text-xs mt-1 opacity-75">
                          {new Date(message.timestamp).toLocaleString()}
                        </p>
                      )}
                    </div>
                  </div>
                ))
            ) : (
              <p className="text-gray-500">No recent messages</p>
            )}
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6">
          <h3 className="text-lg font-semibold mb-4">Recent Calls</h3>
          <div className="space-y-4">
            {selectedUser.calls && selectedUser.calls.length > 0 ? (
              selectedUser.calls.slice(-5).map((call, index) => (
                <div key={index} className="border-b pb-4">
                  <a
                    href={`https://beta.retellai.com/dashboard/history?history=${call.call_id}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-700 hover:text-blue-900 font-medium"
                  >
                    Call {index + 1}
                  </a>
                  <p className="mt-2 text-sm text-gray-600">{call.summary}</p>
                </div>
              ))
            ) : (
              <p className="text-gray-500">No recent calls</p>
            )}
          </div>
        </div>
      </div>

      {/* Matches & Controls Section */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <div className="flex items-center justify-between mb-6">
          <h3 className="text-xl font-semibold">Matches & Controls</h3>
          <div className="flex gap-3">
            <a
              href={`/matches/${id}`}
              target="_blank"
              rel="noreferrer"
              className="inline-flex items-center justify-center px-4 py-2 border border-emerald-800 text-emerald-800 rounded-md hover:bg-emerald-50"
            >
              Find More Matches
            </a>
            <button
              onClick={() => generateMatch(selectedUser.phone_number)}
              className="px-4 py-2 bg-emerald-800 text-white rounded-md hover:bg-emerald-900"
            >
              Generate Match
            </button>
          </div>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div>
            <h4 className="text-lg font-medium mb-4">Sent Matches</h4>
            <div className="space-y-4">
              {selectedUser.sent_matches?.map((match, index) => (
                <div key={index} className="border-b pb-4">
                  <div className="flex items-center justify-between">
                    <a
                      href={`${constants.STAGING_SITE_URL}/profile?matchId=${match._id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-700 hover:text-blue-900"
                    >
                      {match.prospect_name} (Age: {match.prospect_age})
                    </a>
                    <div className="space-x-2">
                      <button
                        onClick={() => handleEditClick(match)}
                        className="text-blue-600 hover:text-blue-800"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => handleDeleteClick(match._id)}
                        className="text-red-600 hover:text-red-800"
                      >
                        Archive
                      </button>
                      <button
                        onClick={() => createIntroduction(match._id)}
                        className="text-green-600 hover:text-green-800"
                      >
                        Create Intro
                      </button>
                    </div>
                  </div>
                  {match.introduction_requested && (
                    <span className="text-sm text-green-600 mt-2">
                      Introduction Requested
                    </span>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div>
            <h4 className="text-lg font-medium mb-4">Pending Matches</h4>
            <div className="space-y-4">
              {selectedUser.outstanding_matches
                ?.filter((match) => !match["has_been_sent"])
                .map((match, index) => (
                  <div key={index} className="border-b pb-4">
                    <div className="flex items-center justify-between">
                      <a
                        href={`${constants.STAGING_SITE_URL}/profile?matchId=${match._id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-700 hover:text-blue-900"
                      >
                        {match.prospect_name} (Age: {match.prospect_age})
                      </a>
                      <div className="space-x-2">
                        <button
                          onClick={() => handleEditClick(match)}
                          className="text-blue-600 hover:text-blue-800"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleDeleteClick(match._id)}
                          className="text-red-600 hover:text-red-800"
                        >
                          Archive
                        </button>
                        <button
                          onClick={() => sendMatch(match._id)}
                          className="text-green-600 hover:text-green-800"
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      {/* Onboarding */}
      <div className="flex flex-col gap-6 mb-8">
        <div className="bg-white rounded-lg shadow-md p-6 w-full">
          <h3 className="text-xl font-semibold mb-6">Bio & Preferences</h3>
          <div>
            <h4 className="text-lg font-medium mb-4">Bio</h4>
            <p className="text-gray-700 whitespace-pre-wrap">
              {selectedUser.bio}
            </p>

            <h4 className="text-lg font-medium mt-6 mb-4">Looking For</h4>
            <ul className="list-disc pl-5 space-y-2">
              {selectedUser.looking_for?.split("\n").map((line, index) => (
                <li key={index} className="text-gray-700">
                  {line.replace("-", "")}
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 w-full">
          <h3 className="text-xl font-semibold mb-6">
            {selectedUser.onboarding_responses
              ? "Onboarding Responses"
              : "Questionnaire Responses"}
          </h3>
          {selectedUser.onboarding_responses ? (
            <div className="space-y-6">
              <div className="grid grid-cols-2 gap-4">
                <div className="p-4 bg-gray-50 rounded-md">
                  <h4 className="font-medium text-gray-900 mb-2">Education</h4>
                  <p className="text-gray-700">
                    {selectedUser.onboarding_responses.education}
                  </p>
                </div>
                <div className="p-4 bg-gray-50 rounded-md">
                  <h4 className="font-medium text-gray-900 mb-2">Profession</h4>
                  <p className="text-gray-700">
                    {selectedUser.onboarding_responses.profession}
                  </p>
                </div>
                {selectedUser.onboarding_responses.company_name && (
                  <div className="p-4 bg-gray-50 rounded-md">
                    <h4 className="font-medium text-gray-900 mb-2">Company</h4>
                    <p className="text-gray-700">
                      {selectedUser.onboarding_responses.company_name}
                    </p>
                  </div>
                )}
              </div>

              {Object.entries(selectedUser.onboarding_responses).map(
                ([key, value]) => {
                  if (Array.isArray(value) && value.length > 0) {
                    return (
                      <div key={key} className="p-4 bg-gray-50 rounded-md">
                        <h4 className="font-medium text-gray-900 mb-3 capitalize">
                          {key.replace(/_/g, " ")}
                        </h4>
                        <ul className="space-y-2">
                          {value.map((item, index) => (
                            <li key={index} className="text-gray-700">
                              {item}
                            </li>
                          ))}
                        </ul>
                      </div>
                    );
                  }
                  return null;
                },
              )}
            </div>
          ) : selectedUser.questionnaire_response ? (
            <div className="bg-gray-50 p-4 rounded-md">
              {Object.entries(selectedUser.questionnaire_response).map(
                ([key, value]) => {
                  if (key === "question_0QjojA") {
                    return (
                      <div key={key} className="mb-3">
                        <span className="font-medium text-gray-700">
                          {key}:{" "}
                        </span>
                        <div className="grid grid-cols-3 gap-4 mt-2">
                          {(value as any[]).map((photo) => (
                            <img
                              key={photo.id}
                              src={photo.url}
                              alt={photo.name}
                              className="w-full h-48 object-cover rounded-lg"
                            />
                          ))}
                        </div>
                      </div>
                    );
                  }
                  return (
                    <div key={key} className="mb-3">
                      <span className="font-medium text-gray-700">{key}: </span>
                      <span className="text-gray-600">
                        {JSON.stringify(value)}
                      </span>
                    </div>
                  );
                },
              )}
            </div>
          ) : (
            <p className="text-gray-500">No responses available.</p>
          )}
        </div>
        {selectedUser.photos && selectedUser.photos.length > 0 && (
          <div className="mt-6">
            <h4 className="text-lg font-medium mb-3">Photos</h4>
            <div className="flex flex-wrap gap-4">
              {selectedUser.photos.map((photo, index) =>
                typeof photo === "string" ? (
                  <img
                    key={index}
                    src={`data:image/png;base64,${photo}`}
                    alt={`User-${index + 1}`}
                    className="w-32 h-32 object-cover rounded-lg"
                  />
                ) : (
                  <img
                    key={index}
                    src={photo.url}
                    alt={`User-${index + 1}`}
                    className="w-32 h-32 object-cover rounded-lg"
                  />
                ),
              )}
            </div>
          </div>
        )}
      </div>

      {/* Edit Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-[1000px] max-h-[90vh] overflow-y-auto">
            <h3 className="text-xl font-semibold mb-4">Edit Match Details</h3>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium mb-2">Note:</label>
                <textarea
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                  className="w-full h-[200px] border rounded-md p-2"
                />
              </div>
              <div>
                <label className="block text-sm font-medium mb-2">Bio:</label>
                <textarea
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                  className="w-full h-[200px] border rounded-md p-2"
                />
              </div>
            </div>
            <div className="flex justify-end gap-4 mt-6">
              <button
                onClick={() => setIsModalOpen(false)}
                className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveChanges}
                className="px-4 py-2 bg-emerald-800 text-white rounded-md hover:bg-emerald-900"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default withAuthenticationRequired(UserDetailsPage, {
  returnTo: "/",
  onRedirecting: () => <UserDetailsPage />,
});
