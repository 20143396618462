import React, { FC, useState, useEffect } from "react";

import Select from "react-select";
import { useIntroductions } from "../../hooks/useIntroductions";
import { useProspectDetails } from "../../hooks/useProspectDetails";
import Modal from "../../components/Modal";
import ProspectSummary from "./ProspectSummary";

interface Introduction {
  _id: string;
  client_user_id: string;
  status: "pending" | "in progress" | "accepted" | "rejected";
  match_id: string;
  prospect_is_curate_member: boolean;
  prospect_profile_id: string | null;
  prospect_summary: string | null;
  created_at: string;
  prospect_base64_image: string | null;
  client_bio: string | null;
  prospect_phone_number: string | null;
}

const statusOptions = [
  { value: "pending", label: "Pending" },
  { value: "in progress", label: "In Progress" },
  { value: "in progress (followed up)", label: "In Progress (Followed Up)" },
  { value: "accepted", label: "Accepted" },
  { value: "rejected", label: "Rejected" },
];

const isValidPhoneNumber = (phone: string) => {
  const phoneRegex = /^\d{10}$/;
  return phoneRegex.test(phone.replace(/[\s()-]/g, ""));
};

const Introductions: FC = () => {
  const {
    introductions,
    loading,
    error,
    performedOutreachEvents,
    updatePerformedOutreachEvents,
    fetchIntroductions,
    updateIntroductionStatus,
    setProspectPhoneNumbertoIntroduction,
  } = useIntroductions();

  const {
    prospectInstagramHandle,
    prospectLinkedinUrl,
    prospectEmail,
    prospectPhoneNumber,
    setProspectInstagramHandle,
    setProspectLinkedinUrl,
    setProspectEmail,
    setProspectPhoneNumber,
    fetchProspectDetails,
    updateProspectDetails,
  } = useProspectDetails();

  const [selectedProspect, setSelectedProspect] = useState<Introduction | null>(
    null,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBioModalOpen, setIsBioModalOpen] = useState(false);
  const [selectedBio, setSelectedBio] = useState<string | null>(null);
  const [searchName, setSearchName] = useState(
    new URLSearchParams(window.location.search).get("s") || "",
  );
  const [editingPhone, setEditingPhone] = useState<{
    id: string;
    value: string;
  } | null>(null);

  const editPhoneRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        editPhoneRef.current &&
        !editPhoneRef.current.contains(event.target as Node)
      ) {
        setEditingPhone(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const searchParam = params.get("s");
    fetchIntroductions(searchParam || undefined);
  }, [fetchIntroductions]);

  const handleStatusChange = async (
    introductionId: string,
    newStatus: string,
  ) => {
    await updateIntroductionStatus(
      introductionId,
      performedOutreachEvents,
      newStatus as
        | "pending"
        | "in progress"
        | "in progress (followed up)"
        | "accepted"
        | "rejected"
        | "stale",
    );
    fetchIntroductions();
  };

  const handleSearchByName = async () => {
    const params = new URLSearchParams(window.location.search);
    params.set("s", searchName);
    window.history.replaceState(
      {},
      "",
      `${window.location.pathname}?${params}`,
    );
    fetchIntroductions(searchName);
  };

  const handleClear = () => {
    setSearchName("");
    const params = new URLSearchParams(window.location.search);
    params.delete("s");
    window.history.replaceState({}, "", window.location.pathname);
    fetchIntroductions();
  };

  const handleOpenModal = async (prospect: Introduction) => {
    if (prospect.prospect_profile_id) {
      await fetchProspectDetails(prospect.prospect_profile_id);
    }
    setSelectedProspect(prospect);
    setIsModalOpen(true);
  };

  const handleUpdateProspectDetails = async () => {
    if (selectedProspect && selectedProspect.prospect_profile_id) {
      if (prospectPhoneNumber) {
        await setProspectPhoneNumbertoIntroduction(
          selectedProspect._id,
          prospectPhoneNumber,
        );
      }
      await updateProspectDetails(selectedProspect.prospect_profile_id, {
        email: prospectEmail,
        phone_number: prospectPhoneNumber,
        instagram_handle: prospectInstagramHandle,
        linkedin: prospectLinkedinUrl,
      });
      setIsModalOpen(false);
    }
  };

  const handleOpenBioModal = (bio: string) => {
    setSelectedBio(bio);
    setIsBioModalOpen(true);
  };

  const handlePhoneUpdate = async (introId: string, newPhone: string) => {
    if (!isValidPhoneNumber(newPhone)) {
      alert("Please enter a valid phone number");
      return;
    }

    try {
      // Add +1 prefix if not already present
      const formattedPhone = newPhone.startsWith("+1")
        ? newPhone
        : `+1${newPhone}`;

      // Call the hook to update the phone number in the backend
      await setProspectPhoneNumbertoIntroduction(introId, formattedPhone);

      // If the prospect has a profile, also update the prospect details
      if (selectedProspect?.prospect_profile_id) {
        await updateProspectDetails(selectedProspect.prospect_profile_id, {
          phone_number: formattedPhone,
          email: prospectEmail,
          instagram_handle: prospectInstagramHandle,
          linkedin: prospectLinkedinUrl,
        });
      }

      setEditingPhone(null);
      fetchIntroductions();
    } catch (error) {
      alert("Failed to update phone number");
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="p-4">
      <h1 className="text-3xl font-bold mb-4">Introductions</h1>
      <form
        className="flex gap-2 mb-4 w-1/2"
        onSubmit={(e) => {
          e.preventDefault();
          if (searchName.trim()) {
            handleSearchByName();
          }
        }}
      >
        <div className="relative flex-1">
          <input
            type="text"
            placeholder="Search prospect or member..."
            className="border rounded px-3 py-2 w-full"
            value={searchName}
            onChange={(e) => setSearchName(e.target.value)}
          />
        </div>
        <button
          type="submit"
          className={`${
            !searchName.trim() ? "bg-gray-400" : "bg-blue-500"
          } text-white px-4 py-2 rounded flex items-center gap-2`}
          disabled={!searchName.trim()}
        >
          Search
        </button>
        {searchName.trim() && (
          <button
            type="button"
            className="bg-gray-500 text-white px-4 py-2 rounded flex items-center gap-2 ml-2"
            onClick={() => handleClear()}
          >
            Clear
          </button>
        )}
      </form>
      <div className="overflow-x-auto shadow-md sm:rounded-lg">
        <table className="min-w-full divide-y divide-gray-200 bg-white">
          <thead className="bg-gray-50">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Prospect Photo
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Client Bio
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider w-[25px]"
              >
                Match ID
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider min-w-[300px]"
              >
                Prospect Summary
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Phone Number
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Created At
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Intro Links
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Status
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {introductions.map((intro: Introduction) => (
              <tr
                key={intro._id}
                className="hover:bg-gray-50 transition-colors duration-200"
              >
                <td className="px-6 py-4 whitespace-nowrap min-w-[220px]">
                  <img
                    src={`data:image/jpeg;base64,${intro.prospect_base64_image}`}
                    alt="Prospect Photo"
                    className="w-[200px] h-[200px] object-cover rounded-lg shadow-sm"
                  />
                </td>
                <td className="px-6 py-4">
                  <div className="text-sm text-gray-900">
                    {(intro.client_bio || "N/A")
                      .split(" ")
                      .slice(0, 20)
                      .join(" ")}
                    {intro.client_bio &&
                      intro.client_bio.split(" ").length > 20 &&
                      "..."}
                  </div>
                  {intro.client_bio && (
                    <div className="mt-2 space-x-2">
                      <button
                        onClick={() =>
                          navigator.clipboard.writeText(intro.client_bio || "")
                        }
                        className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        Copy
                      </button>
                      <button
                        onClick={() =>
                          handleOpenBioModal(intro.client_bio || "")
                        }
                        className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                      >
                        View
                      </button>
                    </div>
                  )}
                </td>
                <td className="px-6 py-4 text-xs text-gray-500">
                  {intro.match_id.split("").map((char, index) => (
                    <React.Fragment key={index}>
                      {char}
                      {(index + 1) % 4 === 0 && <br />}
                    </React.Fragment>
                  ))}
                </td>
                <td className="px-6 py-4" style={{ width: "300px" }}>
                  <ProspectSummary summary={intro.prospect_summary || ""} />
                </td>
                <td className="px-6 py-4">
                  {editingPhone?.id === intro._id ? (
                    <div ref={editPhoneRef} className="flex gap-2">
                      <input
                        type="text"
                        value={editingPhone.value}
                        onChange={(e) =>
                          setEditingPhone({
                            id: intro._id,
                            value: e.target.value,
                          })
                        }
                        className="block w-32 rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        placeholder="5551230000"
                      />
                      <button
                        onClick={() =>
                          handlePhoneUpdate(intro._id, editingPhone.value)
                        }
                        className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-green-600 hover:bg-green-700"
                      >
                        Save
                      </button>
                    </div>
                  ) : (
                    <div className="flex gap-2 items-center">
                      <span className="text-sm text-gray-900">
                        {intro.prospect_phone_number || prospectPhoneNumber}
                      </span>
                      <button
                        onClick={() =>
                          setEditingPhone({
                            id: intro._id,
                            value: prospectPhoneNumber || "",
                          })
                        }
                        className="inline-flex items-center px-2.5 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-blue-600 hover:bg-blue-700"
                      >
                        Edit
                      </button>
                    </div>
                  )}
                </td>
                <td className="px-6 py-4 text-sm text-gray-500">
                  {new Date(intro.created_at).toLocaleString()}
                </td>
                <td className="px-6 py-4">
                  <div className="flex flex-col gap-2">
                    <a
                      href={`https://fit.curate.date/introduction-request-nophoto?matchId=${intro.match_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-900 text-sm font-medium"
                    >
                      No Photo link
                    </a>
                    <a
                      href={`https://fit.curate.date/introduction-request?matchId=${intro.match_id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-900 text-sm font-medium"
                    >
                      Photo link
                    </a>
                  </div>
                </td>
                <td className="px-6 py-4" style={{ width: "200px" }}>
                  <Select
                    options={statusOptions}
                    value={statusOptions.find(
                      (option) => option.value === intro.status,
                    )}
                    onChange={(option) =>
                      handleStatusChange(intro._id, option?.value || "")
                    }
                    className="text-sm"
                  />
                </td>
                <td className="px-6 py-4">
                  <button
                    onClick={() => handleOpenModal(intro)}
                    className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    View Contact Details
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isModalOpen && selectedProspect && (
        <Modal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          title="Contact Details"
        >
          <h2 className="text-xl font-semibold mb-4">Contact Details</h2>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Email</label>
            <input
              type="text"
              value={prospectEmail || ""}
              onChange={(e) => setProspectEmail(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              Phone Number
            </label>
            <input
              type="text"
              value={prospectPhoneNumber || ""}
              onChange={(e) => setProspectPhoneNumber(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              Instagram Handle
            </label>
            <input
              type="text"
              value={prospectInstagramHandle || ""}
              onChange={(e) => setProspectInstagramHandle(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">
              LinkedIn URL
            </label>
            <input
              type="text"
              value={prospectLinkedinUrl || ""}
              onChange={(e) => setProspectLinkedinUrl(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Actions</h3>
            <div className="grid grid-cols-2 gap-2">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={performedOutreachEvents.includes("Sent email 1")}
                  onChange={(e) =>
                    updatePerformedOutreachEvents(
                      "Sent email 1",
                      e.target.checked,
                    )
                  }
                />{" "}
                Sent email 1
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={performedOutreachEvents.includes("Sent email 2")}
                  onChange={(e) =>
                    updatePerformedOutreachEvents(
                      "Sent email 2",
                      e.target.checked,
                    )
                  }
                />{" "}
                Sent email 2
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={performedOutreachEvents.includes("Sent SMS 1")}
                  onChange={(e) =>
                    updatePerformedOutreachEvents(
                      "Sent SMS 1",
                      e.target.checked,
                    )
                  }
                />{" "}
                Sent SMS 1
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={performedOutreachEvents.includes("Sent SMS 2")}
                  onChange={(e) =>
                    updatePerformedOutreachEvents(
                      "Sent SMS 2",
                      e.target.checked,
                    )
                  }
                />{" "}
                Sent SMS 2
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={performedOutreachEvents.includes("Followed on IG")}
                  onChange={(e) =>
                    updatePerformedOutreachEvents(
                      "Followed on IG",
                      e.target.checked,
                    )
                  }
                />{" "}
                Followed on IG
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={performedOutreachEvents.includes(
                    "Sent IG message 1",
                  )}
                  onChange={(e) =>
                    updatePerformedOutreachEvents(
                      "Sent IG message 1",
                      e.target.checked,
                    )
                  }
                />{" "}
                Sent IG message 1
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={performedOutreachEvents.includes(
                    "Sent IG message 2",
                  )}
                  onChange={(e) =>
                    updatePerformedOutreachEvents(
                      "Sent IG message 2",
                      e.target.checked,
                    )
                  }
                />{" "}
                Sent IG message 2
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={performedOutreachEvents.includes(
                    "Connected on LinkedIn",
                  )}
                  onChange={(e) =>
                    updatePerformedOutreachEvents(
                      "Connected on LinkedIn",
                      e.target.checked,
                    )
                  }
                />{" "}
                Connected on LinkedIn
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={performedOutreachEvents.includes(
                    "Sent LinkedIn Message 1",
                  )}
                  onChange={(e) =>
                    updatePerformedOutreachEvents(
                      "Sent LinkedIn Message 1",
                      e.target.checked,
                    )
                  }
                />{" "}
                Sent LinkedIn Message 1
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={performedOutreachEvents.includes(
                    "Sent LinkedIn Message 2",
                  )}
                  onChange={(e) =>
                    updatePerformedOutreachEvents(
                      "Sent LinkedIn Message 2",
                      e.target.checked,
                    )
                  }
                />{" "}
                Sent LinkedIn Message 2
              </label>
            </div>
          </div>
          <div className="flex justify-end space-x-2">
            <button
              onClick={handleUpdateProspectDetails}
              className="px-4 py-2 bg-blue-500 text-white rounded"
            >
              Update
            </button>
            <button
              onClick={() => setIsModalOpen(false)}
              className="px-4 py-2 bg-gray-500 text-white rounded"
            >
              Close
            </button>
          </div>
        </Modal>
      )}

      {isBioModalOpen && selectedBio && (
        <Modal
          isOpen={isBioModalOpen}
          onClose={() => setIsBioModalOpen(false)}
          title="Client Bio"
        >
          <p className="whitespace-pre-wrap">{selectedBio}</p>
        </Modal>
      )}
    </div>
  );
};

export default Introductions;
