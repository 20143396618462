import constants from "../constants";

export const getIntroductions = async (accessToken: string, searchName?: string) => {
  const url = new URL(`${constants.BACKEND_API_ENDPOINT}/internal/introductions`);
  if (searchName) {
    url.searchParams.append('search_name', searchName);
  }
  const response = await fetch(url.toString(), {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
  if (!response.ok) {
    throw new Error("Failed to fetch introductions");
  }
  return response.json();
};

export const updateIntroductionStatusService = async (
  introductionId: string,
  performedOutreachEvents: string[],
  newStatus:
    | "pending"
    | "in progress"
    | "in progress (followed up)"
    | "accepted"
    | "rejected"
    | "stale",
  accessToken: string
) => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/internal/updateIntroductionStatus`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        introduction_id: introductionId,
        new_status: newStatus,
        performed_outreach_events: performedOutreachEvents,
      }),
    }
  );
  if (!response.ok) {
    throw new Error("Failed to update introduction status");
  }
  return response.json();
};

export const setProspectPhoneNumberService = async (
  introductionId: string,
  phoneNumber: string,
  accessToken: string
) => {
  const response = await fetch(
    `${constants.BACKEND_API_ENDPOINT}/internal/setProspectPhoneNumber`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        introduction_id: introductionId,
        phone_number: phoneNumber,
      }),
    }
  );
  if (!response.ok) {
    throw new Error("Failed to set prospect phone number");
  }
  return response.json();
};
